// Imports
import { h, render } from 'preact';

// Project imports
import DiscoveryApi from '@js/discovery/DiscoveryApi';
import DiscoveryInsights from '@js/discovery/insights/DiscoveryInsights';
import {StorySummary, StorySummaryColumnsWrapper} from '@js/templates/StorySummary';

class DiscoveryRecommendRelated {
    constructor() {
        this.attr = 'data-related-stories';
        this.relatedStoriesEl = document.querySelectorAll(`[${this.attr}]`);
    }

    // Create a separate function to fetch data
    async fetchData(el) {
        if (el) {
            const attributeValues = el.getAttribute(this.attr).split(',');

            // Get object ID
            const currentObjectID = Number.parseFloat(attributeValues[0] || null);
            // Get if children story
            const forChildren = Number.parseFloat(attributeValues[1] || 0);

            if (currentObjectID) {
                try {
                    const response = await fetch(`${DiscoveryApi.apiUrl()}v1/recommend/related/${currentObjectID}?children=${forChildren}`);
                    if (!response.ok) {
                        throw new Error(`Network response was not ok: ${response.status}`);
                    }

                    const data = await response.json();
                    return data.data.hits;
                } catch (error) {
                    console.error('Fetch error:', error);
                    return [];
                }
            }
        }
    }

    async init() {
        for (const el of this.relatedStoriesEl) {
            const relatedResults = await this.fetchData(el);

            if (relatedResults.length > 0) {
                // Add new results
                const hits = relatedResults.map(hit => <StorySummary hit={hit} style="columns" />);

                const contentToRender = (
                    <StorySummaryColumnsWrapper>
                        {hits}
                    </StorySummaryColumnsWrapper>
                );

                // Render results
                render(contentToRender, el);

                // Show section (parent parent of the element)
                el.parentElement.parentElement.classList.remove('hidden');

                // Reinit click tracking on elements
                DiscoveryInsights.trackClicks(el);
            }
        }
    }
}

export default new DiscoveryRecommendRelated();
