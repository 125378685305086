// Imports
import aa from 'search-insights';

// Project modules
import utils from '@js/utils/utils';

class DiscoveryInsights {
    constructor() {
        this.algoliaUserTokeCookie = '_ALGOLIA';
        this.aaInitialised = false;
        this.readFunctionHasRun = false;

        this.init();
        this.setUserToken();

        this.trackClicks();

        document.addEventListener('DOMContentLoaded', () => {
            this.trackViews();
            this.trackRead();
        });
    }

    init() {
        aa('init', {
            appId: import.meta.env.VITE_ALGOLIA_APP_ID,
            apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_API_KEY,
            useCookie: true, // since v2, this is false by default
        });

        this.aaInitialised = true;

        console.log('Discovery insights started');
    }

    setUserToken() {
        if (typeof APP !== 'undefined') {
            const userId = APP.userId;

            if (userId) {
                // Set user token
                // aa('setUserToken', userId);
                aa('setAuthenticatedUserToken', userId);

                console.log('Discovery insights user token set to ', userId);
            }
        }
    }

    getUserToken() {
        let ut = null;

        aa('getUserToken', {}, (err, userToken) => {
            if (err) {
                console.error(err);
                return null;
            }

            // Get userToken if not empty else get value from cookie
            ut = !utils.isEmptyOrNullOrUndefined(userToken) ? userToken : utils.getCookie(this.algoliaUserTokeCookie);
        });

        return ut;
    }

    getAuthenticatedUserToken() {
        let ut = null;

        aa('getAuthenticatedUserToken', {}, (err, authenticatedUserToken) => {
            if (err) {
                console.error(err);
                return null;
            }

            // Output authenticatedUserToken
            ut = authenticatedUserToken;
        });

        return ut;
    }

    /**
     * Track the objects
     *
     * @param {array} values - The attr values to pass in, including the index and IDs
     * @param {string} method - The name of the method. clicked|converted|viewed
     * @param {string} eventName - The name of the event to pass in - https://www.algolia.com/doc/guides/sending-events/guides/plan/#events-for-algolia-recommend
     * @param {int} timeout - The timeout duration. Defaults to 0
     */
    aaTrackObjects(values = [], method = 'clicked', eventName = null, timeout = 0) {
        console.log({values});

        // Test if index was passed
        if (!values) {
            console.warn('No `Values` passed to AA tracking');
            return false;
        }

        const userToken = this.getUserToken();
        let methodName = null;

        // Get the method name
        if (method === 'clicked') {
            methodName = 'clickedObjectIDs';
        } else if (method === 'clickedAfterSearch') {
            methodName = 'clickedObjectIDsAfterSearch';
        } else if (method === 'converted') {
            methodName = 'convertedObjectIDs';
        } else if (method === 'convertedAfterSearch') {
            methodName = 'convertedObjectIDsAfterSearch';
        } else if (method === 'viewed') {
            methodName = 'viewedObjectIDs';
        } else if (method === 'filterClicked') {
            methodName = 'clickedFilters';
        } else if (method === 'filterConverted') {
            methodName = 'convertedFilters';
        }

        // Set warning if not methodName is passed
        if (!methodName) {
            console.warn('No `methodName` passed to AA tracking');
        }

        if (methodName && !utils.isEmptyOrNullOrUndefined(userToken)) {
            // Get values to pass to tracking
            const valuesArray = values.split(';').map(value => value.trim());
            const [index, objectsValue] = valuesArray;

            // Index name
            const indexName = this._getIndexName(index);

            setTimeout(() => {
                let trackDetails = {
                    userToken: userToken, // required for Node.js
                    index: indexName,
                    eventName: eventName,
                };

                // Pass values as array
                // Test if index if for topics or not
                if (index === 't') {
                    // Topics
                    const filterVal = objectsValue;

                    // Add filter
                    trackDetails.filters = [`_tags:${encodeURIComponent(filterVal)}`];
                }
                else {
                    // Not topics
                    const objectIds = this._arrayOfValues(objectsValue);

                    // Add objectIds param
                    trackDetails.objectIDs = objectIds;
                }

                const authUserToken = this.getAuthenticatedUserToken();
                if (authUserToken) {
                    trackDetails.authenticatedUserToken = authUserToken;
                }

                // Send event to Algolia Insights
                aa(methodName, trackDetails);
            }, timeout);
        }
    }

    /**
     * Track clicks
     *
     * @param {nodeList} items - The node item to add the event listener to, if blank it will get all element from the document
     */
    trackClicks(items) {
        const trackClick = (el, attributeName) => {
            el.addEventListener('click', () => {
                const attrValues = el.getAttribute(attributeName);
                const method = attrValues.split(';')[2] ?? 'clicked';

                this.aaTrackObjects(attrValues, method, 'Item ' + method);
            });
        };

        const addEventListeners = (attributeName, items) => {
            const els = items ? items.querySelectorAll(`[${attributeName}]`) : document.querySelectorAll(`[${attributeName}]`);
            els.forEach(el => trackClick(el, attributeName));
        };

        addEventListeners('data-track-click', items);
        addEventListeners('data-track-click2', items);
    }

    trackViews() {
        const attributeName = 'data-track-view';
        const els = document.querySelectorAll(`[${attributeName}]`);

        els.forEach(el => {
            const attrValues = el.getAttribute(attributeName);

            // Wait for user to be on page for X seconds before sending viewed event
            this.aaTrackObjects(attrValues, 'viewed', 'Item Viewed', 2000);
        });
    }

    trackRead() {
        const attributeName = 'data-track-read';
        const content = document.querySelector(`[${attributeName}]`);

        if (content) {
            // 200ms debounce time
            // Wrap the function inside an arrow function to allow arguments passed into the function
            this.debouncedCheckBottomPosition = utils.debounce(() => {
                this._checkBottomPosition(attributeName, content);
            }, 200);

            // Run the function on DOMContentLoaded
            document.addEventListener('DOMContentLoaded', this.debouncedCheckBottomPosition);

            // Run the function on scroll and resize using the debounced version
            window.addEventListener('scroll', this.debouncedCheckBottomPosition);
            window.addEventListener('resize', this.debouncedCheckBottomPosition);
        }
    }

    /**
     * Get index name
     *
     * @param {string} index - The index indicator. Options: s|u
     *
     * @returns {string} - The index name
     *
     * @private
     */
    _getIndexName(index) {
        let indexName = null;

        if (index === 's' || index === 'st') {
            indexName = import.meta.env.VITE_ALGOLIA_STORIES_INDEX_NAME;
        }
        else if(index === 'u') {
            indexName = import.meta.env.VITE_ALGOLIA_USERS_INDEX_NAME;
        }
        else if(index === 't') {
            indexName = import.meta.env.VITE_ALGOLIA_TOPICS_INDEX_NAME;
        }

        return import.meta.env.VITE_ENV + '_' + indexName;
    }

    /**
     * Convert list of comma separated ids to arrays and trim any space
     *
     * @param {string} values - Comma separated list
     *
     * @returns {array} - The array of values (objectIDs)
     *
     * @private
     */
    _arrayOfValues(values) {
        // Trim the input string
        const trimmedValues = values.trim();

        // Check if the input contains commas
        if (trimmedValues.includes(',')) {
            // If there are commas, split and parse the values
            return trimmedValues.split(',').map(item => item.trim());
        } else {
            // If there are no commas, parse the single value
            return [trimmedValues];
        }
    }

    /**
     * Check if element is at the bottom or beyond the bottom of the viewport
     *
     * @param {string} attributeName - The attr name of the element
     * @param {element} el - The element for the content
     *
     * @private
     */
    _checkBottomPosition(attributeName, el) {
        const contentRect = el.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        const attrValues = el.getAttribute(attributeName);

        // Check if the bottom of the content is at or below the bottom of the viewport
        if (contentRect.bottom <= viewportHeight && !this.readFunctionHasRun) {
            console.log('Bottom of content is at or below the bottom of the viewport');
            this.aaTrackObjects(attrValues, 'converted', 'Item Read');

            // Set the flag to true so the function doesn't run again
            this.readFunctionHasRun = true;
        }
    }
}

export default new DiscoveryInsights();
