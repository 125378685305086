// Project imports
import Config from '@js/config/Config';

class DiscoveryApi {
    apiUrl() {
        let envApiUrl = '';

        if (Config.env === 'dev') {
            envApiUrl = '-dev';
        } else if (Config.env === 'staging') {
            envApiUrl = '-staging';
        }

        return `https://discover${envApiUrl}.short-stories.co/api/`;
    }
}

export default new DiscoveryApi();
