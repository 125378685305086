const Config = {
    env: import.meta.env.VITE_ENV,
    assetCdnUrl: import.meta.env.VITE_ASSET_CDN_URL,
    algoliaAppId: import.meta.env.VITE_ALGOLIA_APP_ID,
    algoliaStoriesIndexName: import.meta.env.VITE_ENV + '_' + import.meta.env.VITE_ALGOLIA_STORIES_INDEX_NAME,
    algoliaUsersIndexName: import.meta.env.VITE_ENV + '_' + import.meta.env.VITE_ALGOLIA_USERS_INDEX_NAME,
    algoliaTopicsIndexName: import.meta.env.VITE_ENV + '_' + import.meta.env.VITE_ALGOLIA_TOPICS_INDEX_NAME,
    algoliaListsIndexName: import.meta.env.VITE_ENV + '_' + import.meta.env.VITE_ALGOLIA_LISTS_INDEX_NAME,
    algoliaSearchOnlyApi: import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_API_KEY,
    algoliaSearchQuerySuggestionsIndexName: import.meta.env.VITE_ALGOLIA_SEARCH_QUERY_SUGGESTIONS_INDEX_NAME
};

export default Config;
